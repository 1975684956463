
import Vue from "vue";
export default Vue.extend({
  name: "form-builder-documentation",
  data() {
    return {
      links: [
        {
          title: "Advanced",
          to: "/form-builder/examples/advanced"
        },
        {
          title: "Yes or no",
          to: "/form-builder/examples/yesno"
        },
        {
          title: "Login",
          to: "/form-builder/examples/login"
        },
        {
          title: "MultiStep",
          to: "/form-builder/examples/multi-step"
        }
      ]
    };
  }
});
